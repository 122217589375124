<template>
    <AdminToast />
    <div class='grid'>

        <div class='col-12 md:col-6 lg:col-3'>

            <div class='card p-6 h-full flex flex-column'>

                <div class='widget-header flex flex-wrap align-items-center justify-content-between pt-0 pb-0'>
                    <project-employee-left-panel :data-item='dataItem'></project-employee-left-panel>
                </div>

                <span>
					<hr>
				</span>

                <span>

				<Button label='Yardım' icon='pi pi-question-circle' @click='visibleLeft = true' class='p-button-danger mr-2 p-2 pl-3 pr-3' />

				<Sidebar v-model:visible='visibleLeft' :baseZIndex='1000'>
					<h3>İletişim Bilgileri</h3>

					<p class="text-lg">
                        Bu alanda personel iletişim bilgilerini görüntülüyorsunuz. <br>
                        <br>
                        Personel bilgilerini güncellemek için güncelle butonuna tıklayın.
					</p>

				</Sidebar>

                <Button label='Güncelle' icon='pi pi-pencil' class='p-button-success mr-2 p-2 pl-3 pr-3' @click='editPers' />

				</span>

            </div>

        </div>

        <div class='col-12 md:col-6 lg:col-9'>

            <div class='card p-fluid p-6 h-full flex flex-column'>

                <project-employee-tab-menu :employee-id='employeeId' :project-employee-id='projectEmployeeId'></project-employee-tab-menu>
                
                <div class='col-12'>
                    
                    <div class='field grid mb-5'>
                        <label class='col-12 mb-2 md:col-2 md:mb-0 font-bold'>Adres</label>
                        <div class='col-12 md:col-10'>
                            {{ dataItem.address }}
                        </div>
                    </div>

                    <div class='field grid mb-5'>
                        <label class='col-12 mb-2 md:col-2 md:mb-0 font-bold'>İl</label>
                        <div class='col-12 md:col-10'>
                            {{ dataItem.cityName }}
                        </div>
                    </div>

                    <div class='field grid mb-5' v-if='dataItem.cityId > 0'>
                        <label class='col-12 mb-2 md:col-2 md:mb-0 font-bold'>İlçe</label>
                        <div class='col-12 md:col-10'>
                            {{ dataItem.townName }}
                        </div>
                    </div>

                    <div class='field grid mb-5'>
                        <label class='col-12 mb-2 md:col-2 md:mb-0 font-bold'>GSM</label>
                        <div class='col-12 md:col-10'>
                            {{ dataItem.gsm }}
                        </div>
                    </div>

                    <div class='field grid mb-5'>
                        <label class='col-12 mb-2 md:col-2 md:mb-0 font-bold'>E-Posta Adresi</label>
                        <div class='col-12 md:col-10'>{{ dataItem.email }}
                        </div>
                    </div>

                    <div class='field grid mb-5'>
                        <label class='col-12 mb-2 md:col-2 md:mb-0 font-bold'>Acil Durum Kişisi</label>
                        <div class='col-12 md:col-10'>
                            {{ dataItem.emergencyPersonName }}
                        </div>
                    </div>

                    <div class='field grid mb-5'>
                        <label class='col-12 mb-2 md:col-2 md:mb-0 font-bold'>Acil Durum Kişisi
                            GSM</label>
                        <div class='col-12 md:col-10'>
                            {{ dataItem.emergencyPersonPhone }}
                        </div>
                    </div>

                </div>

            </div>

        </div>

    </div>
</template>

<script>


import {
    showErrorMessage,
    showSuccessMessage,
} from '../../components/shared/toast/toastFunction';
import AdminToast from '@/components/shared/toast/adminToast';
import EmployeeService from '../../services/employeeService';
import AddressService from '../../services/addressService';
import ProjectEmployeeLeftPanel from './projectEmployeeLeftPanel';
import ProjectEmployeeTabMenu from './projectEmployeeTabMenu';
import { getEmployee, getProjectEmployee } from '../common/commonFunctions';
import { checkActiveProjectIdIsValid } from '../common/commonConstantFunctions';

export default {
    components: { ProjectEmployeeTabMenu, ProjectEmployeeLeftPanel, AdminToast },
    _employeeService: null,
    _addressService: null,
    created() {
        this._addressService = new AddressService();
        this._employeeService = new EmployeeService();
        this.employeeId = this.$route.params.employeeId;
        this.projectEmployeeId = this.$route.params.projectEmployeeId;
    },
    async mounted() {
        this.dataItem = await getEmployee(this.employeeId);
        this.projectEmployee = await getProjectEmployee(this.projectEmployeeId);
        checkActiveProjectIdIsValid(this, this.projectEmployee.projectId);
        await this.fillDropDowns(this.dataItem.cityId);
    },
    data() {
        return {
            employeeId: 0,
            projectEmployeeId:0,
            dataItem: {
                id: '',
                name: '',
                surName: '',
                isActive: '',
                insertDate: '',
                identityNumber: '',
                birthday: '',
                gender: '',
                education: '',
                lastSchool: '',
                militaryStatus: '',
                maritalStatus: '',
                foreignLanguage: '',
                childCount: '',
                address: '',
                cityId: '',
                townId: '',
                gsm: '',
                email: '',
                emergencyPersonName: '',
                emergencyPersonPhone: '',
                employeePhoto: '',
            },
            display: false,
            visibleLeft: false,
        };
    },
    methods: {
        editPers () {
            this.$router.push({ name: 'editEmployeeContact', params: { employeeId: this.employeeId } });
        },
        async save() {

            if (this.validateForm()) {
                const updateResponse = await this._employeeService.updateEmployeeContactInformation(this.employeeId, this.dataItem);
                if (updateResponse.isSuccess) {
                    showSuccessMessage(this, 'Çalışan güncelleme başarılı');
                } else {
                    showErrorMessage(this, 'Çalışan güncellemede sorun ile karşılaşıldı');
                }
            } else {
                showErrorMessage(this, 'Çalışan güncellemede sorun ile karşılaşıldı');
            }

        },
        validateForm() {

            return true;
        },

        async fillDropDowns(cityId) {
            let cityResponse = await this._addressService.getCities(90);
            if (cityResponse.isSuccess) {
                this.cityList = cityResponse.data;
            }
            if (cityId != null && cityId > 0) {
                let townResponse = await this._addressService.getTowns(cityId);
                if (townResponse.isSuccess) {
                    this.townList = townResponse.data;
                }
            }

        },
        async cityChanged(event) {
            let townResponse = await this._addressService.getTowns(event.value);
            if (townResponse.isSuccess) {
                this.townList = townResponse.data;
            }
        },
    },
};

</script>


<style scoped>

</style>
